







































































import { Vue, Component, Prop } from 'vue-property-decorator'
import CopyText from '@/components/common/CopyText.vue'
import { compressImg, imageRotate } from '@/components/common/compressImg'
import SecurityText from '@/components/common/SecurityText/index.vue'
import api from '@/api'

@Component({
  name: 'photoDialog',
  components: {
    CopyText,
    SecurityText
  }
})
export default class PhotoDialog extends Vue {
  @Prop({ default: 'star' }) private project: string
  @Prop({ default: () => api.base }) api: AnyObj

  private seen = false
  private current = ''
  private showFlag = false
  private loading = false
  private selectionPhotoId = ''
  private imageData = ''
  private imageUrl = ''
  private inputimageUrl = ''
  private getImgUrl = ''
  private dataImage: any[] = []
  private newImage: any[] = []
  private previewImgUrl = ''
  private name = ''
  private id = ''
  private curl = ''
  private tipText = ''
  private tipShow = false
  private isOperated = false
  private nameVisible = false

  private focusInput() {
    this.tipShow = false
    this.tipText = ''
  }
  enter(id: string) {
    if (this.curl != id) {
      this.seen = true
      this.current = id
    }
  }
  private leave() {
    this.seen = false
    this.current = ''
  }
  async open(data: any = {}) {
    this.isOperated = false
    this.showFlag = true
    this.nameVisible = false
    this.$nextTick(() => {
      this.selectionPhotoId = ''

      this.tipShow = false
      this.tipText = ''

      this.inputimageUrl = ''
      this.previewImgUrl = ''
      this.name = data.name
      this.id = data.id
      if (this.project === 'country') {
        this.id = data.personId
      }
      this.getHistoryPhoto(this.id)
    })
  }
  async getHistoryPhoto(id: string) {
    const resp = await this.$api.base.getPersonsHistoryPhotoList(id)
    this.$nextTick(() => {
      if (resp.data && resp.data.data) {
        this.dataImage = resp.data.data.slice(0)
        this.newImage = resp.data.data
        const represent = resp.data.data.find((i: any) => i.represent)
        this.curl = represent ? represent.id : ''
      }
    })
  }
  async changeImg(id: string, url: string) {
    if (id) {
      await this.representPic(id)
    }
    this.imageUrl = url
    this.curl = id
  }
  private message(msg: string, type: MessageType) {
    this.$message({
      message: msg,
      type: type
    })
  }
  private cancelDialog() {
    this.showFlag = false
    if (this.isOperated) {
      this.$emit('refresh')
    }
  }
  // 打开图片上传
  private uploadHeadImg() {
    const hiddenInput: any = this.$refs.hiddenInput
    hiddenInput.click()
  }
  private getImage(data: string) {
    // 当两次上传同一张图片，依然对接口做请求
    if (this.previewImgUrl === data) {
      this.loadImg()
      return
    }
    this.previewImgUrl = data
  }
  private rotateImage(file: File | string) {
    imageRotate(file, this.getImage)
  }
  // 将头像显示
  private async handleFile(e: any) {
    const $target = e.target || e.srcElement
    const file = $target.files[0]
    const result = await compressImg(file)
    let imgUrl = ''
    result.onload = data => {
      const res: any = data.target || data.srcElement
      imgUrl = res.result
      this.rotateImage(imgUrl)
    }
    if (!imgUrl && !result.onload) {
      //兼容ie10
      imgUrl = result.result as string

      this.previewImgUrl = imgUrl
    }
    e.target.value = ''
  }

  private async loadPic(url: string) {
    const regUrl = /^(ht|f)tps?:\/\/.*/

    if (!url) {
      this.tipShow = true
      this.tipText = '网址为空，请重新输入'
    } else if (!regUrl.test(url)) {
      this.previewImgUrl = url
      this.tipShow = true
      this.tipText = '网址无效，请重新输入'
      this.previewImgUrl = ''
    } else {
      if (url === this.previewImgUrl) {
        await this.addPic(this.previewImgUrl)
      }
      this.previewImgUrl = url
    }
  }

  private loadError() {
    if (this.previewImgUrl) {
      this.tipShow = true
      this.tipText = '无法读取到图片文件, 请重新输入'
      this.previewImgUrl = ''
    }
  }

  private async loadImg() {
    if (this.previewImgUrl) {
      await this.addPic(this.previewImgUrl)
    }
  }

  private async addPic(url: string) {
    this.loading = true
    let imageData1, imageUrl1
    if (url.indexOf('http://') != -1 || url.indexOf('https://') != -1) {
      imageUrl1 = url
    } else {
      imageData1 = url.split(',')[1]
    }
    const CreateImg = {
      [imageData1 ? 'imageData' : 'imageUrl']: imageData1 || imageUrl1
    }
    let res
    try {
      this.isOperated = true
      res = await this.api.addPersonImage(this.id, CreateImg)
      if (res.data.code === 0) {
        const { data } = res.data
        if (data.represent) this.curl = data.id
        this.message('图片上传成功', 'success')
        this.newImage.push(res.data.data)
        this.previewImgUrl = ''
      }
    } catch (error) {
      this.loading = false
      return false
    }
  }

  private async deletePic(picId: string) {
    this.loading = true
    this.$confirm('人员图片一经删除无法恢复，是否确定删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(async () => {
        try {
          this.isOperated = true
          const resp = await this.$api.base.getPersonsDeletePhoto(this.id, picId)
          if (resp.status && resp.status === 204) {
            this.message('图片删除成功', 'success')
            this.newImage = this.newImage.filter(e => e.id != picId)
          }
        } catch (error) {
          this.loading = false
          return false
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  // 将图片设置为代表图
  private async representPic(selectionPhotoId: string) {
    const selectPhoto = {
      represent: true
    }
    if (selectionPhotoId) {
      this.isOperated = true
      const res = await this.$api.base.getPersonsUpdatePhoto(this.id, selectionPhotoId, selectPhoto)
      if (res.data.code === 0) {
        this.message('选择照片成功', 'success')
      }
    }
  }
}
